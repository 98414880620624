import { Button, ButtonSubmit, Link, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelect, { links as multiSelectStyles } from '~/components/Utility/components/MultiSelect';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { MultiValue } from 'react-select';
import type { ApiResponse, Group, Job, MultiSelectOption, Topic } from 'types';

export const links: LinksFunction = () => [...multiSelectStyles()];

type Props = {
  onRequestClose: OnRequestCloseType;
  topic: Topic;
};

const DuplicateTopicsModal = ({ onRequestClose, topic }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<Job>();
  const groupsFetcher = useFetcher<ApiResponse<Group[]>>();
  const isGroupsLoading =
    groupsFetcher.state === 'loading' || (groupsFetcher.state === 'idle' && groupsFetcher.type !== 'done');
  const [selectedGroupIds, setSelectedGroupIds] = useState<MultiValue<MultiSelectOption>>([]);
  const isSubmitting = fetcher.state !== 'idle';

  const submissionSuccess = fetcher.type === 'done' && fetcher.data?.status === 'complete';
  const submissionPending = fetcher.type === 'done' && ['queued', 'working'].includes(fetcher.data?.status);
  const submissionError = fetcher.type === 'done' && fetcher.data?.status === 'failed';
  const noGroupsAvailable = groupsFetcher.type === 'done' && groupsFetcher.data && groupsFetcher.data.data.length === 0;

  useEffect(() => {
    const shouldLoadGroups = groupsFetcher.state === 'idle' && !groupsFetcher.data;

    if (shouldLoadGroups) {
      const params = {
        per_page: '250',
        standalone: 'true',
        load_all: 'true',
      };
      const formattedParams = new URLSearchParams(params);
      groupsFetcher.load(`${resourceRoutes.myGroups}?${formattedParams.toString()}`);
    }
  }, [groupsFetcher]);

  const getFormattedGroups = useCallback((): MultiSelectOption[] => {
    const groups = groupsFetcher.data?.data;
    if (!groups) return [];
    return groups.map(group => ({ value: group.id, label: group.name }));
  }, [groupsFetcher.data?.data]);

  const hasError = () => {
    const hasSubmitted = fetcher.type === 'done' || isSubmitting;
    return selectedGroupIds.length === 0 && hasSubmitted;
  };

  if (submissionError)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('common.oops')}</h1>
        <p className="fk-modalBody">{t('duplicateTopicsModal.duplicateError')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateTopicsModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionPending)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.stillWorking')}</h1>
        <p className="fk-modalBody">{t('duplicateTopicsModal.duplicationTopicsInProgress')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateTopicsModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionSuccess)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('duplicateTopicsModal.duplicationComplete')}</h1>
        <p className="fk-modalBody">{t('duplicateTopicsModal.yourTopicDuplicationFinished')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateTopicsModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (noGroupsAvailable)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.duplicateToGroups')}</h1>
        <p className="fk-modalBody">{t('shared.holdOnYouNeedGroups')}</p>
        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="duplicateTopicsModal__button__noThanks" onClick={onRequestClose}>
            {t('shared.noThanks')}
          </Button>
          <Link data-testid="duplicateTopicsModal__link__createGroup" variant="button" to={routes.GROUPS_NEW}>
            {t('shared.createGroup')}
          </Link>
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form method="post" action={routes.GROUPS_ID_TOPICS_FUNC(topic.grid_id)}>
        <h1 className="fk-modalHeader">{t('shared.duplicateToGroups')}</h1>
        <p className="fk-modalBody">{t('duplicateTopicsModal.duplicateTopicToGroups')}</p>
        <div className="mt1 mb1">
          {isGroupsLoading ? (
            <Loader container />
          ) : (
            <MultiSelect
              displayLabel={t('shared.selectGroups')}
              data-testid="duplicateTopicsModal__multiSelect__groupsSelector"
              onChange={(selected: MultiValue<MultiSelectOption>) => setSelectedGroupIds(selected)}
              options={getFormattedGroups()}
              placeholder={t('shared.selectOrTypeToSearch')}
              error={hasError() ? t('shared.selectDestinationGroup') : undefined}
            />
          )}
        </div>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateTopicModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="duplicateTopics"
            data-testid="duplicateTopicModal__button__duplicate"
            loading={isSubmitting}
            disabled={isGroupsLoading}
          >
            {t('shared.duplicate')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="copy_mode" value="shallow" />
        <input type="hidden" name="ids" value={topic.id} />
        <input
          type="hidden"
          name="to_grids"
          value={JSON.stringify(selectedGroupIds.map(option => option.value.toString()))}
        />
      </fetcher.Form>
    </Modal>
  );
};

export default DuplicateTopicsModal;
