import {
  Dropdown,
  IcFluentBookStar24Regular,
  IcFluentClosedCaptions24Regular,
  IcFluentDelete24Regular,
  IcFluentEdit24Regular,
  IcFluentFlag24Regular,
  IcFluentMoreHorizontal24Regular,
  IcFluentQrCode24Regular,
  IcFluentRestore24Regular,
  IcFluentTextboxAlignBottom24Regular,
  ModalConsumer,
  TopicResourceType,
} from '@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { has } from 'lodash-es';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ActiveStateDropdown from '../Dropdown/ActiveStateDropdown';
import ExportDataDropdown from '../Dropdown/ExportDataDropdown';
import DropdownLink from '../FkWrappers/DropdownLink';
import ImmersiveReaderButton from '../ImmersiveReader/ImmersiveReaderButton';
import DuplicateTopicsModal, { links as duplicateTopicsModalStyles } from '../Modals/Duplicate/DuplicateTopicsModal';
import ExportModal from '../Modals/ExportModal';
import DeleteTopicModal from '../Modals/TopicList/DeleteTopicModal';
import ReportModal, { links as reportModalStyles } from '~/components/Report/ReportModal';
import adminExternalLinks from '~/constants/adminExternalLinks';
import endpoints from '~/constants/endpoints';
import HandleIds from '~/constants/handleIds';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';
import { downloadCsv } from '~/helper/component/downloadCsv';
import { isFlagPresent } from '~/helper/helper';
import useGetUser from '~/hooks/useGetUser';

import type { LinksFunction } from '@remix-run/node';
import type { Job, RouteTyping, Topic } from 'types';

export const links: LinksFunction = () => [...reportModalStyles(), ...duplicateTopicsModalStyles()];

type Props = {
  isGroupLead: boolean;
  topic: Topic;
  variant: 'header' | 'table';
};

const TopicActionsDropdown = ({ isGroupLead, topic, variant }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(globalContext);
  const location = useLocation();
  const fetcher = useFetcher();
  const exportDataFetcher = useFetcher<Job | string>();
  const matches = useMatches();
  const loggedInUser = useGetUser();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const group = (matches.find(({ handle }) => handle?.id === HandleIds.Group)?.data as RouteTyping<'Group'>)?.group
    ?.data;
  const { env } = matches[0].data;
  const videoCount = topic.all_response_count + topic.all_reply_count;
  const isUsernameGroup = group.access_control === 'student';
  const isLtiGroup = checkIsLtiGroup(group);
  const isPassportGroup = loggedInUser && loggedInUser.identities?.includes('passport');

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  useEffect(() => {
    if (exportDataFetcher.type === 'done' && exportDataFetcher.data) {
      if (typeof exportDataFetcher.data === 'string') {
        downloadCsv(exportDataFetcher.data, 'responses.csv', 'text/csv', ua?.safari);
        announceLiveMessage(t('exportModal.downloadSuccessful'));
      } else if (exportDataFetcher.data.job_id) {
        announceLiveMessage(t('exportModal.emailSent'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDataFetcher, t, ua?.safari]); // announceLiveMessage as a dependency causes an infinite loop

  return (
    <ModalConsumer>
      {({ showModal }) => (
        <Dropdown
          button={
            <Dropdown.Button
              aria-controls=""
              aria-label={t('topicActionsDropdown.dropdownButton')}
              data-testid="topicActionsDropdown__button__dropdown"
              icon={<IcFluentMoreHorizontal24Regular />}
              size="36"
              theme={variant === 'header' ? 'secondary' : 'tertiary'}
            />
          }
        >
          <>
            <ImmersiveReaderButton displayType="dropdown" text={topic.text} title={topic.title} />
            <Dropdown.Divider />

            {isDeprecated
              ? null
              : (isGroupLead ||
                  (group?.member_topic_creation &&
                    topic?.user_id === loggedInUser?.id &&
                    !isUsernameGroup &&
                    !isLtiGroup &&
                    !isPassportGroup)) && (
                  <DropdownLink
                    data-testid="topicActionsDropdown__dropdownItem__editTopic"
                    variant="button"
                    icon={<IcFluentEdit24Regular />}
                    to={routes.GROUPS_ID_TOPICS_ID_UPDATE_FUNC(topic.grid_id, topic.id)}
                  >
                    {t('shared.editTopic')}
                  </DropdownLink>
                )}
            {isDeprecated
              ? null
              : isGroupLead &&
                topic.focus?.type === TopicResourceType.Video && (
                  <DropdownLink
                    data-testid="topicActionsDropdown__dropdownItem__editCaptions"
                    icon={<IcFluentClosedCaptions24Regular />}
                    to={routes.GROUPS_ID_TOPICS_ID_CAPTIONS_FUNC(topic.grid_id, topic.id)}
                  >
                    {t('shared.editCaptions')}
                  </DropdownLink>
                )}
          </>

          {isGroupLead && (
            <>
              {!isDeprecated && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    data-testid="topicActionsDropdown__dropdownItem__duplicateTopic"
                    icon={<IcFluentRestore24Regular />}
                    onSelect={() => showModal(DuplicateTopicsModal, { topic })}
                  >
                    {t('topicActionsDropdown.duplicateTopic')}
                  </Dropdown.Item>

                  <ActiveStateDropdown updateStateFetcher={fetcher} item={topic} type="Topic" />
                  <Dropdown.Divider />
                </>
              )}
              {/* {ua && !ua.mobile && topic.focus && has(topic.focus, 'transcript') && (
                <Dropdown.Item
                  data-testid="topicActionsDropdown__dropdownItem__downloadCaptions"
                  icon={<IcFluentTextboxAlignBottom24Regular />}
                  href={endpoints.TOPIC_TRANSCRIPT_TXT(env.API_URL, topic.grid_id, topic.id)}
                  newTab
                >
                  {t('common.downloadCaptions')}
                </Dropdown.Item>
              )} */}
              {/* <ExportDataDropdown
                fetcher={exportDataFetcher}
                groupId={topic.grid_id}
                openModal={(exportingData: (async: boolean) => void) =>
                  showModal(ExportModal, { exportFunction: exportingData })
                }
                topicId={topic.id}
                type="responses"
                videoCount={videoCount}
              /> */}
              {topic.response_count > 0 ? (
                <DropdownLink
                  icon={<IcFluentQrCode24Regular />}
                  data-testid="topicActionsDropdown__dropdownLink__printQRCodes"
                  to={routes.GROUPS_ID_TOPIC_RESPONSE_BADGES_FUNC(
                    group.id,
                    topic.id,
                    topic.all_response_count,
                    topic.title,
                  )}
                >
                  {t('topicActionsDropdown.printQRCodes')}
                </DropdownLink>
              ) : null}
              {isDeprecated ? null : (
                <Dropdown.Item
                  href={adminExternalLinks.TOPICS_ID_ADD_TO_LIBRARY_URL_FUNC(env.ADMIN_URL, topic.id)}
                  icon={<IcFluentBookStar24Regular />}
                  data-testid="topicActionsDropdown__dropdownItem__addToDiscovery"
                >
                  {t('shared.submitToDiscovery')}
                </Dropdown.Item>
              )}
            </>
          )}

          {isDeprecated
            ? null
            : isGroupLead && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    iconLeft={<IcFluentDelete24Regular />}
                    onSelect={() =>
                      showModal(DeleteTopicModal, {
                        topic,
                        //  if same url don't redirect
                        redirectsTo:
                          location.pathname !== routes.GROUPS_ID_TOPICS_FUNC(topic.grid_id)
                            ? routes.GROUPS_ID_TOPICS_FUNC(topic.grid_id)
                            : undefined,
                      })
                    }
                    data-testid="topicListCard__dropdownItem__ellipsis"
                  >
                    {t('shared.deleteTopic')}
                  </Dropdown.Item>
                </>
              )}
          {/* WIP - https://dev.azure.com/Flipgrid/FG/_workitems/edit/59557} */}
          {/* There are stages to deprecation, the report functionality remains until July 31st, 2024  */}
          {/* <Dropdown.Item
            data-testid="topicActionsDropdown__dropdownItem__reportTopic"
            icon={<IcFluentFlag24Regular />}
            onSelect={() =>
              showModal(ReportModal, {
                contentName: topic.title,
                contentType: 'topic',
                gridId: topic.grid_id,
                topicId: topic.id,
              })
            }
          >
            {t('shared.reportTopic')}
          </Dropdown.Item> */}
        </Dropdown>
      )}
    </ModalConsumer>
  );
};

export default TopicActionsDropdown;
