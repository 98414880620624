import { useMatches } from '@remix-run/react';

import checkIsLtiGroup from '../helper/checkIsLtiGroup';
import handleIds from '~/constants/handleIds';

import type { Group, RouteTyping } from 'types';

export default function useIsLtiGroupOrFrame(group: Group) {
  const isLtiGroup = checkIsLtiGroup(group);
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const inIframe = ua?.inIframe;
  return isLtiGroup || inIframe;
}
