import { Button, IcFluentDismiss24Regular } from '@flipgrid/flipkit';
import { TeachingBubble as FluentTeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useTranslation } from 'react-i18next';
import { useHydrated } from 'remix-utils';

import teachingBubbleStyles from '~/styles/components/TeachingBubble/TeachingBubble.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  currentBubble?: number;
  focusTrapZoneProps?: {};
  onTeachingBubbleClose: () => void;
  target: string;
  totalBubbles?: number;
};

export const links = () => [{ rel: 'stylesheet', href: teachingBubbleStyles }];

const TeachingBubble = ({
  className,
  currentBubble,
  target,
  onTeachingBubbleClose,
  focusTrapZoneProps,
  totalBubbles,
  children
}: Props) => {
  const { t } = useTranslation();
  const isHydrated = useHydrated();
  const hasFooterContent = !!(currentBubble && totalBubbles);

  // FluentUI TeachingBubble does not dismiss when specified target is clicked. This code overrides that behavior.
  if (isHydrated) {
    const targetElement = document.querySelector(target);

    if (targetElement) {
      targetElement.addEventListener('click', onTeachingBubbleClose);
    }
  }

  return (
    <div className="teachingBubble">
      <FluentTeachingBubble
        calloutProps={{ className }}
        target={target}
        onDismiss={onTeachingBubbleClose}
        focusTrapZoneProps={focusTrapZoneProps}
        footerContent={
        hasFooterContent ?
        t('teachingBubble.tourFooterContent', {
          currentBubble,
          totalBubbles
        }) :
        undefined}>


        <div className="fk-theme__dark">
          <Button
            className="teachingBubble__close"
            onClick={onTeachingBubbleClose}
            size="36"
            theme="transparent"
            data-testid="teachingBubble__button__closeBanner"
            aria-label={t('common.close')}
            icon={<IcFluentDismiss24Regular className="iconFill__white" height={20} width={20} />} />

          {children}
        </div>
      </FluentTeachingBubble>
    </div>);

};

export default TeachingBubble;